import React, {useEffect, useState} from "react";

const WeavelyAppContext = React.createContext<any>(null)
let trackingId;

export const AppProvider = ({children}: any) => {
    const [location, setLocation] = useState<any>(undefined)
    const [trackMarker, setTrackMarker] = useState<any>(true)

    const startLocationTracking = () => {
        trackingId = navigator.geolocation.watchPosition(
            (location) => {
                console.log(location)
                setLocation(location.coords)
            },
            (error) => {

            },
            {
                enableHighAccuracy: true,
                timeout: 30000,
                maximumAge: 0,
            }
        )
    }

    const stopLocationTracking = () => {
        navigator.geolocation.clearWatch(trackingId);
    }


    return (
        <WeavelyAppContext.Provider
            value={{
                location,
                trackMarker, setTrackMarker,
                startLocationTracking, stopLocationTracking
            }}>
            {children}
        </WeavelyAppContext.Provider>
    )
}

export const useApp = () => React.useContext(WeavelyAppContext)
