import React, {useEffect, useState} from 'react';
import L from 'leaflet'
import 'leaflet/dist/leaflet.css'
import './Map.css'
import '../util/Leaflet.TileLayer.MBTiles.js'
import 'leaflet-rotatedmarker'
import {useApp} from "../hooks/use-app";


//import 'leaflet-tilelayer-mbtiles-ts';
let marker;
let map;
const airplaneIcon = L.icon({
    iconUrl: `${process.env.PUBLIC_URL}/img/marker.svg`,
    iconSize: [35, 35],
})

export const Map = () => {

    const {location, trackMarker, startLocationTracking} = useApp()

    useEffect(() => {

        map = L.map('map', {
            minZoom: 8,
            maxZoom: 11,
            zoomControl: false
        }).setView([50.90041002465284, 4.485745176183045], 10)

        //mbtiles.addTo(map);
        // @ts-ignore
        new L.TileLayer.MBTiles('tiles/ebbu_256.mbtiles', {
            minZoom: 6,
            maxZoom: 11,
        }).addTo(map);
        startLocationTracking()

        map.on('movestart', (e) => {
            console.log(e)
        })
        map.on('autopanstart')
    }, [])


    useEffect(() => {
        if (location) {
            const position = new L.LatLng(location.latitude, location.longitude)
            marker ? marker.setLatLng(position) : marker = L.marker(position, {
                icon: airplaneIcon,
                // @ts-ignore
                rotationOrigin: 'center center'
            }).addTo(map);
            // @ts-ignore
            marker.setRotationAngle(location.heading)
            if (trackMarker) {
                map.setView(position);
            }
        }
    }, [location])

    return (
        <div id="map">

        </div>
    );
}

