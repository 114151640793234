import React from 'react';
import {Map} from "./components/Map";

import {InstrumentPanel} from "./components/InstrumentPanel";

function App() {
    return (
        <>
            <Map/>
            <InstrumentPanel/>
        </>
    );
}

export default App;
