import React, {useEffect, useState} from 'react';

import './InstrumentPanel.css'

import {useApp} from "../hooks/use-app";


export const InstrumentPanel = () => {

    const {location} = useApp()
    const [speed, setSpeed] = useState<any>(null)
    const [altitude, setAltitude] = useState<any>(null)
    const [heading, setHeading] = useState<any>(null)

    useEffect(() => {
        if (location) {
            location.altitude && setAltitude(`${(location.altitude / 0.3048).toFixed(0)}`)
            location.speed && setSpeed(`${(location.speed * 1.9438452).toFixed(0)}`)
            location.heading && setHeading(`${location.heading.toFixed(0)}`)
        }
    }, [location])

    return (
        <div className={'instrument-panel'}>
            <div className={'instrument'}>
                <small>SPEED</small>
                {speed || '--'}
                {/*<small>kts</small>*/}
            </div>
            <div className={'instrument'}>
                <small>ALTITUDE</small>
                {altitude || '--'}
                {/*<small>feet</small>*/}
            </div>
            <div className={'instrument'}>
                <small>HEADING</small>
                {heading || '--'}
                {/*<small>deg</small>*/}
            </div>
        </div>
    );
}

